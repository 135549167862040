.company-documents-preview{
    position: relative;
    /* width: 317px; */
    height: 162px;
    border: 1px solid #335F32;
    border-radius: 8px;
}

.company-documents-preview > img{
    width: 100%; 
    height: -webkit-fill-available;
    border-radius: 8px;
}

.text-wrapper{
    /* width: 315px;  */
}


.delete-company-documents-btn{
    position: absolute;
    z-index: 4;
    right: 10px;
    bottom: 10px;
}