.icon-name{
    font-size: 12px;
    text-align: center;   
}

.icon_ > svg{
width: 35px;
fill: #4B4B4B;
}

.icon-selector-container:hover{
    z-index: -10 !important;
    border: 1px groove #4ba24a11;
    border-radius: 8px;
    background: #10ed1009;
    cursor: pointer;
    box-shadow: 6px 2px 4px -8px;
} 

.icon-selector-container:hover .icon-name{ 
    color: #335f32;
    cursor: pointer;
}

.icon-selector-container:hover .icon_ > svg{
    width: 35px;
    fill: #3eae3c !important;
    stroke: #335f32;
    cursor: pointer;
}