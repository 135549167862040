.card-header{
    display: flex;
    align-items: center;
    background-color: #335F32;
    color: #FCFCFC !important;
    font: normal normal bold 16px "Red Hat Display";
    height: 84px;
}
.customer-image {
    width: 100px;
    height: 70px;
    border-radius: 50%;
}

.right{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #335F32;
}

.right > hr{
    content: "";
    height: 300px;
    width: 0px;
    border: 1px solid #707070;
    opacity: 0.15;
    margin-right: 10px;
}

.prop-text{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 5px;
    font: normal normal bold 12px "Red Hat Display";
    letter-spacing: 0px;
    color: #4B4B4B;
    opacity: 1;
}

.right-image{
    height: 210px;
    width: 181px;
}

.right-prop-details > p, .address{
    font: normal normal normal 14px "Red Hat Display";
    color: #000000;
}

.right-prop-details >  .row{
    padding-bottom: 10px;
}

 .left{
    padding-top: 40px;
 }

 .customer-name{
    font: normal normal bold 16px "Red Hat Display";
    letter-spacing: 0px;
    color: #000000;
 }

 .customer-contact{
    font: normal normal bold 14px/12px "Red Hat Display";
    letter-spacing: 0px;
    color: #335F32;
    display: flex;
    align-items: center;
 }

 .customer-contact > svg{
    width: 18px !important;
 }

 .active-tab{
    color: #335F32;
    font: normal normal bold 12px "Red Hat Display";
 }

 .inactive-tab{
    font: normal normal normal  12px "Red Hat Display"; 
    color: #4B4B4B;
 }

 .tab{
    cursor: pointer;
 }

 .feature > span{
    font: normal normal normal 12px "Red Hat Display";
    letter-spacing: 0px;
    color: #000000;
 }

 .feature > svg{
    width: 27px !important;
 }