/* TabGroup.css */

.tab-group {
    font: normal normal bold 14px/32px Red Hat Display;
    padding-top: 15px;
  }
  
  .tab-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    border-bottom: 1px solid #ccc;
  }
  
  .tab {
    padding: 1px 15px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: -1px; /* Align with the bottom border */
    opacity: 1;
    color: #707070;
    letter-spacing: 0px;
    text-align: left;
  }
  
  .tab.is-active{
    border-bottom: 3px solid #335f32;
    border-color: #335f32;
    color: #335f32;
  }
  
  .tab-content {
    /* border: 1px solid #ccc; */
    padding-top: 20px;
    padding-bottom: 50px;
  }
  