.wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #DDF8E7;
    width: 147px !important;
    height: 45px;
    border: 1px solid #335F32;
    border-radius: 100px;
}

.wrapper-danger{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #FFDEDE;
    width: 147px !important;
    height: 45px;
    border: 1px solid #D10808;
    border-radius: 100px;
} 

.text{
    color: #335F32;
    font-size: 12px;
    font-weight: 800;
}

.text-ndanger{
    color: #D10808;
    font-size: 12px;
    font-weight: 800;
}

.icon-left{
    padding-bottom: 2px;
}

.icon-right{
    display: inline-flex;
    border: 2px solid #0A7E32;
    border-radius: 50%;
    height: 17px;
    width: 17px;
} 
.icon-right-danger{
    display: inline-flex;
    border: 2px solid #D10808;
    border-radius: 50%;
    height: 17px;
    width: 17px;
} 

.description{
    border: 2px solid #335F32;
    border-radius: 8px;
    position: absolute;
    top: -25px !important;
    right: 385px;
    background: white;
    z-index: 4;
    height: 150px !important;
    width: 448px !important;
    padding: 25px; 
}

.description-text{
    font-size: 12px;
    font-family: 'Red Hat Display', sans-serif;
    text-wrap: wrap;
    font-weight: 500;
}

.description-title{
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
}