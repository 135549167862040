.pr-card {
  max-width: 316px !important;
  height: 200px !important;
  border: none !important;
  overflow-x: hidden;
  overflow-y: visible;
  cursor: pointer;
  transition: transform 0.2s ease-in;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
}

.slider-body {
  height: 50% !important;
  overflow-x: hidden;
  /* overflow-y:visib */
}

.card-details {
  height: 50% !important;
}

.pr-title,
.pr-desc,
.pr-hou {
  font-family: "Red Had Display", sans-serif;
}

.pr-title > h6 {
  font-weight: bold;
  font-size: 18px;
}

.pr-desc {
  font-size: 16px;
  overflow: hidden;
  margin: 0 !important;
}

.pr-hou > span {
  font-weight: bold;
  font-size: 15px;
}

.pr-carousel-body {
  max-height: 658px;
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
}

.p-carousel-next {
  border: 1px solid #707070;
  border-radius: 50px;
  padding: 15px;
  margin-right: 10px;
  color: #335f32;
  background-color: #ffffff;
}

.p-carousel-next > svg {
  width: 29px;
  height: 29px;
  fill: #335f32;
}

.p-carousel-prev {
  border: 1px solid #707070;
  border-radius: 50px;
  padding: 15px;
  margin-right: 10px;
  color: #335f32;
  background-color: #ffffff;
}

.p-disabled {
  border: 1px solid #70707069;
  background-color: #70707073;
  color: #ffffff90;
}

.p-carousel-prev > svg {
  width: 29px;
  height: 29px;
  fill: #335f32;
}

.pr-carousel-body_alt {
  max-height: 658px;
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
}

.pr-cr-cd {
  padding: 0px !important;
  overflow: hidden;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.pr-cr-cd > img {
  width: 315px;
  height: 94px;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
}

.slick-arrow {
  border: 2px solid #707070;
  border-radius: 50%;
  height: 49px;
  width: 49px;
  background-color: #335f32;
  z-index: 1;
}

.slick-slide {
  width: 345px;
}

.dt-bn {
  border: 1px solid #d8d8d8;
  border-radius: 8px !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px;
  padding: 0;
}

.rdt_TableHeadRow {
  background: #f0f0f0 !important;
  height: 62px;
}

.pr-sli {
  max-height: 200px !important;
}

.slick-list {
  height: 200px !important;
}

.search-form {
  margin: auto auto 20px auto;
}

.wrpper {
  margin: 30px auto;
}

.pr-card:hover {
  transform: scale(1.03);
  transition: transform 0.2s ease-in;
}

.estate {
  color: inherit !important;
  text-decoration: none;
}
.card-footer {
  box-shadow: 0px 3px 6px #00000029 !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.create-estate {
  font-size: 16px;
  font-weight: 600;
  font-family: "Red Hat Display", sans-serif;
  color: #335f32 !important;
}

.slick-track {
  width: auto !important;
  padding-left: 1.5rem;
  padding-right: 75px;
}

.slick-slide {
  width: 360px !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 40px;
  line-height: 0;
  opacity: 4.75;
  color: white;
  border: 1px solid black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 50%;
  background: aquamarine;
}

/* ADD ESTATE and HOUSE MODAL CSS  */
.estate-card,
.house-card {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  height: 14rem !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.house-card {
  height: 18rem !important;
}

.estate-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.estate-card .estate-img {
  height: 70%;
  padding: 0;
}

.house-card .house-img {
  padding: 0;
  height: 50%;
}

.estate-img {
  position: relative;
  overflow: hidden;
  height: 70% !important;
  padding: 0;
}

.house-img {
  position: relative;
}

.estate-img::before,
.house-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

.estate-card:hover .estate-img::before,
.house-card:hover .house-img::before {
  opacity: 1;
}

.estate-img img,
.house-img img {
  width: 100%;
  height: 100%;
}

.price {
  position: absolute;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: bold;
  color: white;
  background-color: green;
  border-radius: 100px;
  padding: 0.3rem 0.5rem;
  margin: 0.5rem;
}

.status {
  position: absolute;
  bottom: 1.2rem;
  right: 0rem;

  display: flex;
  align-items: center;
  gap: 0.1rem;
  height: 1.3rem;
  font-weight: bold;
  color: green;
  background-color: #f4fff4;
  border-radius: 100px;
  border: 1px solid green;
  padding: 0 0.3rem;
  margin: 0.5rem;
  z-index: 999;
  overflow: hidden;
}

.status > div > span > svg {
  height: 0.7rem;
}

.status > p {
  font-size: 0.6rem;
}

.estate-card .estate-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70%;
  padding: 10px 0 !important;
  margin: 0 5px;
  overflow: hidden; /* Added */
}

.estate-card .estate-title,
.estate-card .estate-desc {
  text-align: center;
  text-wrap: wrap !important;
}

.estate-card .estate-title {
  margin-bottom: 5px;
}

.estate-card .estate-title h6 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.estate-card .estate-desc p {
  margin: 0;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.estate-card .estate-hou {
  text-align: center;
  margin-top: 5px;
}

.estate-card .estate-hou span {
  font-size: 0.9rem;
  /* font-weight: bold; */
  color: #333;
}

.add-plan {
  font-size: 0.9rem;
  font-weight: bold;
  color: white;
  text-align: center;
  border-radius: 100px;
  padding: 0.3rem;
}

.bage {
  top: 0px !important;
  right: -2px !important;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.3rem;
}

.organization_name {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  color: white;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  background-color: rgba(117, 115, 115, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  text-transform: uppercase;
  padding: 0.4rem;
}
