.checkmark{
  height: 41px;
}
.checkmark > svg{
  width: 32px;
}

.right-arrow > svg{
  width: 30px;
}

.tab-title {
  font-size: 13px;
}

.tab-active{
  font: normal normal bold 15px "Red Hat Display";
}

.tab-pending {
  font: normal normal normal 15px "Red Hat Display";
  color: #000000;
  opacity: 0.5;
}

dt > svg{
  width: 25px;
}
dt{
  font-size: 14px;
}

.item-value{
  font-size: 11px;
  color: gray;
  padding: 5px 12px;
}
.scrollable-tab{
  /* bottom: 0; */
  /* top: 365px; */
  /* padding: 0px 10px; */
  /* margin: 0 10px; */
  /* right: 0; */
  /* position: absolute; */
  overflow-y: scroll;
  overflow-x: hidden;
  height: 65%;
  padding: 20px 10px;
}

.fixed-tab{
  /* position: fixed; */
  height: 30%;
  /* overflow: hidden; */
  /* z-index: 8; */
}
.xc > div{
  overflow-wrap: anywhere;
} 
::-webkit-scrollbar {
  display: none;
} 

.vertical-divider{
  border-right: 1px solid #D8D8D8;
  margin-bottom: 1rem;
}

.icon{
font-weight: 800;
}

.icon >svg{
fill: #335F32;
scale: 1.5;
width: 25px;
}

.feature{
  fill: #0A7E32;
}

.doc-icon{
  width: 1rem;
}

.doc-title, .doc-verify-analyze, 
.doc-view-report {
  width: 7rem !important;
  font-family: 'Red Hat Display', sans-serif;
  text-transform: capitalize !important;
}

.validateCheck {
width: 9rem !important;
}

.doc-check{
font-family: 'Red Hat Display', sans-serif;
font-weight: 700;
}

.rig > button{
width: 6rem !important;
}

.doc-view-report{
  /* font-size: 12px; */
  font-weight: 700;
  color: #335F32;
  /* width: 60% !important; */
  padding: 6px 0 !important;
  margin-left: 1rem;
}  

.space-btw{
width: 63.8% !important;
}

/* .doc-tittle-no-button{
width: 6% !important;
} */

.icon-no-button{
width: 1% !important;
}

tr{
  border-top: 1px solid rgba(224, 224, 224, 0.379) !important;
  border-bottom: 1px solid rgba(224, 224, 224, 0.379)!important;
}

.warning-alert{
  border: 2px solid #957E00 !important;
  background-color: #FEFFE5 !important;
} 

.bad-credit{
  height: 25px;
  width: 100%;
  border: 1px solid #D10808;
  background: #D10808;
  border-radius: 50px;
  display: flex;
  box-shadow: 1px 6px 20px -5px;
}
.bad-credit_badge{
  margin-top:45px;
  margin-left: 25px;
  border: 2px solid #D10808;
  background: #D10808;
  border-radius: 50%;
  height: 100px;
  width: 124px;
  color: #FFF;
  box-shadow: 10px 11px 31px -19px #D10808 !important;
}
.bad-credit_badge::after {
  margin-left: 50px;
  margin-bottom: 335px !important;
  width: 0; 
  border-right: 13px solid transparent;
  border-left: 12px solid transparent;
  content: " ";
  font-size: 0;
  line-height: -52px;
  border-bottom: 27px solid #D10808;
  position: relative;
  top: -109px;
  box-shadow: 10px 11px 31px -19px #D10808 !important;
} 
.bad-credit_badge:hover{
  box-shadow: 10px 11px 21px -15px #D10808 !important;
}

.average-credit{
  height: 25px;
  width: 100%;
  border: 1px solid #D1C314;
  border-radius: 50px;
  display: flex;
  background: #D1C314;
  box-shadow: 1px 6px 20px -5px;
}

.average-credit_badge{
  margin-top:45px;
  margin-left: 25px;
  border: 2px solid #D1C314;
  background: #D1C314;
  border-radius: 50%;
  height: 100px;
  width: 124px;
  color: #FFF;
  box-shadow: 10px 11px 31px -19px #D1C314 !important;
}
.average-credit_badge::after {
  margin-left: 50px;
  margin-bottom: 335px !important;
  width: 0; 
  border-right: 13px solid transparent;
  border-left: 12px solid transparent;
  content: " ";
  font-size: 0;
  line-height: -52px;
  border-bottom: 27px solid #D1C314;
  position: relative;
  top: -109px;
  box-shadow: 10px 11px 31px -19px #D1C314 !important;
}

.average-credit_badge:hover{
  box-shadow: 10px 11px 31px -15px #D1C314 !important;
}

.badge_title, .badge_value{
  font-family: "Red Hat Display", sans-serif;
  color: #000000;
  font-weight: 700;
}

.badge_title{ 
  font-size: 11px; 
}

.badge_value{ 
  font-size: 20px; 
}

.good-credit{
  height: 25px;
  width: 100%;
  border: 1px solid #1BADD0;
  border-radius: 50px;
  display: flex;
  background: #1BADD0;
  box-shadow: 1px 6px 20px -5px;
}


.good-credit_badge{
  margin-top:45px;
  margin-left: 25px;
  border: 2px solid #1BADD0;
  background: #1BADD0;
  border-radius: 50%;
  height: 100px;
  width: 124px;
  color: #FFF;
  box-shadow: 10px 11px 31px -19px #1BADD0 !important;
}
.good-credit_badge::after {
  margin-left: 50px;
  margin-bottom: 335px !important;
  width: 0; 
  border-right: 13px solid transparent;
  border-left: 12px solid transparent;
  content: " ";
  font-size: 0;
  line-height: -52px;
  border-bottom: 27px solid #1BADD0;
  position: relative;
  top: -109px;
  box-shadow: 10px 11px 31px -19px #1BADD0 !important;
} 
.good-credit_badge:hover{
  box-shadow: 10px 11px 31px -15px #1BADD0 !important;
}


.great-credit{
  height: 25px;
  width: 100%;
  border: 1px solid #335F32;
  border-radius: 50px;
  display: flex;
  background: #335F32;
  box-shadow: 1px 6px 20px -5px;
}


.great-credit_badge{
  margin-top: 45px;
  margin-left: -15px;
  border: 2px solid #335F32;
  background: #335F32;
  border-radius: 50%;
  height: 100px;
  width: 124px;
  color: #FFF;
  box-shadow: 10px 11px 31px -19px #335F32 !important;
}
.great-credit_badge:hover{
  box-shadow: 10px 11px 31px -15px #335F32 !important;
}
.great-credit_badge::after {
  margin-left: 50px;
  margin-bottom: 335px !important;
  width: 0; 
  border-right: 13px solid transparent;
  border-left: 12px solid transparent;
  content: " ";
  font-size: 0;
  line-height: -52px;
  border-bottom: 27px solid #335F32;
  position: relative;
  top: -109px;
  box-shadow: 10px 11px 31px -19px #335F32 !important;
} 
.border-container{
   border: 2px solid #D8D8D8 !important;
}
.left-card{
  background-color: #F0F0F0;
  font-size: 1.4rem;
  font-family: 'Millik', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-card{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.right-card > caption{
  padding-top: 25px;
  color: #000000;
  font-size: 1.1rem;
  font-weight: 700;
}

.right-card > p, .bank-desc{
  font-size: 0.8rem;
}

.status-dot{
  display: inline-flex;
  width: 16px;
  height: 16px; 
  border-radius: 50%;
  border: 1px solid #000000;
}
.success{
  background: #0A7E32;
}

.medium{
  background: #D1C314;
}

.high{
  background: #D10808;
}

h5{
  font-size: 18px !important;
  font-family: 'Millik', sans-serif !important;
  color: #000000;
} 

.resizeSpinner{
  width: 1.2rem !important;
  height: 1.2rem !important;
}

::-webkit-input-placeholder {
  text-align: start;
  padding-top: 75px;
}
:-moz-placeholder{
  text-align: center;
  padding-top: 75px;
}
::-moz-placeholder{
  text-align: center;
  padding-top: 75px;
}

:-ms-input-placeholder{
  text-align: start;
  padding-top: 75px;
}
::-moz-focus-inner{
  text-align: center;
  padding: 75px;
}

.propertyImg{
  width: 206px !important;
  height: 164px !important;
}


/* Pill */
.round-start{
  border-bottom-left-radius: 50rem !important;
  border-top-left-radius: 50rem !important;
}

.round-end{
  border-bottom-right-radius: 50rem !important;
  border-top-right-radius: 50rem !important;
}

.bg-active{
  background-color: #335F32; 
  color: #F0F0F0 !important;
}

.tab-item-link{
  font: normal normal normal 16px Millik;
  color: #335F32;
  margin: 0;
}

.checkbox-container {
  position: relative;
  display: inline-block;
}

.checkbox-container .checkbox-icon {
  margin-right: 10px; /* Adjust margin as needed */
  font-size: 24px; /* Adjust the icon size as needed */
   /* Change the color of the icon as needed */
  position: relative;
  z-index: 9000000;
}

.checkbox-container .line {
  position: absolute;
  width: 104%;
  height: 2px; /* Adjust the thickness of the line as needed */
  background-color: black; /* Change the color of the line as needed */
  top: 62%; /* Position the line in the middle of the checkbox */
  transform: translateX(-16%);
}
.tab-pending{
  color: #a9a8a8 !important;
}
.tab-pending > hr {
  color: #C6C6C6 !important;
  opacity: 0.3 !important;
}
.tab-completed{
  background-color: #707070 !important;
}
.tab-active{
  color: #0A7E32 !important;
}
.hr{
color: black;
width: 100%;
height: 5%; 
}
.hr-left{
color: rgb(34, 34, 34);
width: 10%;
height: 5%; 
}  