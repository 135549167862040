.mainLabel {
  font: normal normal bold 16px "Red Hat Display"!important;
  letter-spacing: 0.04px;
  padding: 0 16px 0 8px;
  margin-bottom: 0.5rem;
}

.subLabel {
  display: block;
  font-size: 0.9rem !important;
  padding: 0 16px 7px 8px;
}
