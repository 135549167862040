.dashboard{
    font-family: 'Red Hat Display',  sans-serif;
}

.mrtg-app-card{ 
    border: 1px solid #707070;
    border-radius: 8px;
    opacity: 1;
    /* box-shadow: 8px 11px 6px #07441d1a; */
    height: 327px !important;
    overflow-y: auto;
}

.mrtg-app-card > div.card-header, div.card-footer{
    background: #FFFFFF !important;
    border: none;
    box-shadow: none !important;
    padding: 0 !important;
}
 .card .card-header {
    height: 3rem !important;
    /* padding: 0 !important; */
    /* margin: 0 !important; */
}

.card-header > span, .mrtg-title, .mrtg-body{
    color: #000000;
    font-family: 'Red Hat Display',  sans-serif;
}

.mrtg-title{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding-top: 0 !important;
    margin-top: 0 !important;
}

.no-applications {
    font-size: 18px !important;
    text-align: center !important;
}

.mrtg-body{
    font-weight: 400;
    font-size: 20px !important;
}

/* .dashboard-card_body{
    padding: 26px !important;
} */
