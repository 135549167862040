.profile {
  padding-bottom: 8rem;
}

.company_profile > input {
  display: none;
}

.company_profile {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #0a7e32;
  width: 100px;
  height: 100px;
  overflow: hidden;
  cursor: pointer;
}

.company_profile > span {
  font-size: 14px;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  cursor: pointer;
}

.company_profile > span > svg {
  width: 40px;
  height: 40px;
  fill: #0a7e32;
}
