.mortgage-banks {
  margin: 15px auto;
}

.bank-select-container {
  position: relative;
  top: -90%;
  z-index: 8;
}

.image {
  width: 100px !important;
  height: 100px !important;
  padding: 0 0 10px;
}
.image > img {
  border-radius: 8px;
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.icon > svg {
  cursor: pointer;
  height: 21px;
}

.bnkrows {
  cursor: pointer;
  margin-bottom: 10px;
  border-bottom: 1px solid #8e8d8d2b;
}

.bank-image-details > img {
  height: 300px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 8px;
}

.image-show {
  border-radius: 8px;
  height: 228px;
  cursor: pointer;
  margin: 0 10px;
}
a {
  color: black !important;
  text-decoration: none;
}
.text-name {
  overflow-wrap: anywhere;
}
.dev-img {
  max-height: 170px;
}

.devEmail {
  border-bottom: 2px solid green;
  margin-left: 0.3rem;
}

.devEmail,
.devEmail span {
  font-weight: 500;
  text-decoration: none;
  color: green;
}

.relationships {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 11rem;
  background-color: #335f320d;
  border-radius: 10px;
  padding: 0.4rem;
}

.devIcon > svg,
.docIcon > svg,
.docImg > svg {
  color: green;
  fill: green;
}

.docIcon > svg {
  height: 22px !important;
}

.docImg > svg {
  height: 50px !important;
  width: 50px !important;
  margin: 8px;
  cursor: pointer;
}

/* ADD ESTATE and HOUSE MODAL CSS  */
.estate-card,
.house-card {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  height: 14rem !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.house-card {
  height: 16rem !important;
}

.estate-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.estate-card .estate-img,
.house-card .house-img {
  height: 70%;
  padding: 0;
}

.estate-img,
.house-img {
  position: relative;
  overflow: hidden;
  height: 70% !important;
  padding: 0;
}

.house-img {
  position: relative;
  height: 17rem !important;
}

.estate-img::before,
.house-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

.estate-card:hover .estate-img::before,
.house-card:hover .house-img::before {
  opacity: 1;
}

.estate-img img,
.house-img img {
  width: 100%;
  height: 100%;
}

.price {
  position: absolute;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: bold;
  color: white;
  background-color: green;
  border-radius: 100px;
  padding: 0.3rem 0.5rem;
  margin: 0.5rem;
}

.status {
  position: absolute;
  bottom: 0.2rem;
  right: 0.1rem;

  display: flex;
  align-items: center;
  gap: 0.1rem;
  font-weight: bold;
  color: green;
  background-color: #f4fff4;
  border-radius: 100px;
  border: 1px solid green;
  padding: 0 0.5rem;
  padding-bottom: 0.1rem;
  margin: 0.5rem;
}

.status > span > svg {
  height: 0.9rem;
}

.status > p {
  padding-top: 0.3rem;
  font-size: 0.6rem;
}

.estate-card .estate-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70%;
  padding: 10px 0 !important;
  margin: 0 5px;
  overflow: hidden; /* Added */
}

.estate-card .estate-title,
.estate-card .estate-desc {
  text-align: center;
  text-wrap: wrap !important;
}

.estate-card .estate-title {
  margin-bottom: 5px;
}

.estate-card .estate-title h6 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.estate-card .estate-desc p {
  margin: 0;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.estate-card .estate-hou {
  text-align: center;
  margin-top: 5px;
}

.estate-card .estate-hou span {
  font-size: 0.9rem;
  /* font-weight: bold; */
  color: #333;
}

.add-plan {
  font-size: 0.9rem;
  font-weight: bold;
  color: white;
  text-align: center;
  border-radius: 100px;
  padding: 0.3rem;
}
