.head-trans-text{
    font: normal normal normal 18px/32px Red Hat Display;
    padding-top: 30px;
    font-weight: bold;
}
.trans-text{
    font-family: "Red Hat Display", sans-serif;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0px;
    color: #4B4B4B;
    opacity: 1;
}
.summ-text{
    text-align: left;
    font: normal normal medium 18px/32px Red Hat Display;
    letter-spacing: 0px;
    color: #4B4B4B;
    text-transform: capitalize;
    opacity: 1;
}