@media (max-width: 375px) {
  #layoutSidenav #layoutSidenav_nav {
    flex-basis: 230px !important;
  }
  .d-md {
    display: none !important;
  }
}

logout-icon > span > svg {
  color: #4b4b4b;
  height: 14px !important;
}

/* SIDE TOGGLER EFFECTS */
.sideNav,
.hidde-sideNav {
  min-height: 100vh;
  height: 100%;
  width: 17rem;
  transition: 0.25s all ease;
}

.hidde-sideNav {
  width: 1rem !important;
}

.layoutSidenav_nav,
.layoutSidenav_nav:hover,
.hidde-layoutSidenav_nav,
.hidde-layoutSidenav_nav:hover {
  width: 17rem;
  position: fixed;
  overflow: scroll;
  height: 100%;
  background-color: #f0f0f0;
  /* border-right: 2px solid #91919159; */
  z-index: 98 !important;
  transition: all 0.25s ease;
  clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
}

.hidde-layoutSidenav_nav {
  /* opacity: 0; */
  clip-path: polygon(5% 0, 0 0, 0 100%, 5% 100%);
}

.hidde-layoutSidenav_nav:hover {
  opacity: 1 !important;
  z-index: 99 !important;
}

.hidde-toggler,
.sideNav_toggler {
  position: fixed;
  top: 5.5rem;
  left: 16.2rem;
  cursor: pointer;
  z-index: 99 !important;

  border-radius: 50%;
  opacity: 0;
}

.hidde-toggler {
  left: 0.2rem !important;
}

.sideNav:hover .sideNav_toggler,
.sideNav:hover .hidde-toggler,
.hidde-sideNav .sideNav_toggler,
.hidde-sideNav .hidde-toggler {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #335f32;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #dfffde;
  border: 1px solid #335f32;
  border-radius: 50%;
  opacity: 1;
  transition: all 0.25s ease;
}

/* SIDE TOGGLER EFFECTS ENDS */

.sidenav-bage {
  position: absolute;
  top: -5px;
  right: -10px;
  transform: translate(50%, -50%);
  min-width: 1rem !important;
  height: 1rem !important;
  line-height: 0.9rem;
  text-align: center;
  font-size: 12px !important;
  font-weight: bold;
  border: 1px solid #335f32 !important;
  color: #ffffff !important;
  background-color: #335f32 !important;
  border-radius: 50%;
  padding: 0 3px;
}

.nav-link {
  position: relative;
  color: #000000 !important;
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 100%;
  padding: 0.5rem 0 !important;
  padding-left: 0.5rem !important;
  margin-bottom: 0.8rem;
  text-decoration: none !important;
  transition: all 0.25s ease;
}

.sb-nav-link-icon > span > svg {
  height: 25px !important;
}

.active,
.active-settings {
  color: #335f32 !important;
  font-weight: 900 !important;
  text-decoration: none !important;
  cursor: default !important;
}
.active > img {
  color: #335f32 !important;
  cursor: default !important;
}

.active > span > svg {
  color: #335f32 !important;
  cursor: default !important;
}

.active > .sb-nav-link-icon {
  color: #335f32 !important;
  cursor: default !important;
}

.active > .sb-nav-link-icon > span > svg,
.active_icon > span > svg {
  color: #335f32 !important;
  fill: #335f32 !important;
  stroke: #335f32 !important;
  stroke-width: 1px !important;
  cursor: default !important;
}

.active:hover {
  color: #335f32 !important;
}

.active::after {
  content: "";
  background: #335f32;
  position: absolute !important;
  right: 4px;
  height: 70%;
  width: 3px;
  border-radius: 10px;
  cursor: default !important;
}

.plan_icon > span > svg {
  height: 25px !important;
  stroke: #3c3b3b;
  stroke-width: 2px !important;
}

.active > .plan_icon > span > svg {
  height: 25px !important;
  stroke: #335f32 !important;
  stroke-width: 3px !important;
  cursor: default !important;
}

.nav-link:hover {
  background-color: #f8f8f8 !important;
}

.sb-sidenav .sb-sidenav-menu {
  background-color: #f0f0f0;
  padding-bottom: 4rem;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-nav-link-icon {
  font-size: 20px !important;
  color: #000000;
}

.sb-sidenav-collapse-arrow {
  color: #000000 !important;
}

.sb-nav-link-icon > img {
  font-size: 20px !important;
  color: #000000;
  overflow: visible;
  box-sizing: content-box;
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

/* #viewing-request-icon {
  font-size: 18px !important;
} */

.nav-link-2 {
  color: #000000 !important;
  font-family: "Red Hat Display", sans-serif;
  font-size: 13px !important;
  font-weight: 900;
  width: 100%;
  padding: 0 !important;
  margin-bottom: 0.8rem;
}

.nav-link-setting,
.active-nav-link-setting {
  padding: 0.5rem 0 !important;
  padding-left: 0.5rem !important;
  width: 100%;
  transition: all 0.25s ease;
}

.active-nav-link-setting {
  cursor: default !important;
}

.nav-link-setting:hover {
  background-color: #f8f8f8 !important;
}

.active {
  background-color: transparent !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: default !important;
}

.hover-effect {
  /* font-size: 14px !important; */
  font-weight: 500;
  margin-bottom: 0;
}

.nav-link-3 {
  color: #000000 !important;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  width: 100%;
  padding: 0;
  transition: all 0.25s ease;
}

.hidde,
.nav-link-ul {
  position: relative;
  list-style-type: none;
  padding-left: 45px;
  width: 100%;
  height: 100%;
  margin-bottom: 0 !important;
  transition: all 0.25s ease;
  clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
}

.hidde {
  height: 0 !important;
  transition: all 0.25s ease;
  clip-path: polygon(100% 0, 0 0, 0 0, 100% 0);
}

.hidde > li,
.nav-link-ul > li {
  width: 100%;
  padding: 0;
  margin-left: 5px;
  margin-bottom: 5px;
}

.hidde > li:hover,
.nav-link-ul > li:hover {
  background-color: #f8f8f8 !important;
}

.hidde > li > a,
.nav-link-ul > li > a {
  position: relative;
  display: block;
  padding: 0.4rem;
  width: 100%;
  text-decoration: none !important;
}

.sb-sidenav-footer {
  display: block;
  position: fixed;
  bottom: 0;
  width: 17rem;
  height: 43px;
  font-size: 14px !important;
  font-family: "Red Hat Display", sans-serif;
  font-weight: bold;
  color: red !important;
  background-color: #f0f0f0 !important;
  border-top: 0.2px solid #91919159;
  /* border-right: 2px solid #91919159; */
  cursor: pointer;
}

.logout-icon > span > svg {
  color: red;
  fill: red;
  height: 14px !important;
  margin-left: 18px;
}
