.description{
    border: 2px solid #F2F0F0;
    border-radius: 8px;
    position: absolute;
    bottom: 206px !important;
    right: 6%;
    background: white;
    z-index: 20;
    height: 150px !important;
    width: 448px !important;
    padding: 25px
}

.description-text{
    font-size: 12px;
    font-family: 'Red Hat Display', sans-serif;
    text-wrap: wrap;
    font-weight: 500;
}

.description-title{
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
}

.download_doc_icon > svg {
    fill: #666; 
    margin-right: 0.5rem;
    margin-bottom: 0.5rem !important;
}

.download_doc_icon > svg:hover {
    fill: green; 
}
 