.image_wrapper {
    height: 17rem;
    width: 100%;
    border-radius: 10px;
    border: 2px solid gray;
    overflow: hidden;
  }
  
  .house_img {
    width: 100%;
    height: 100%;
  }
  
  .thumbs-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  }
  
  .thumbs_img {
    width: 3.5rem !important;
    height: 3rem !important;
    border-radius: 10px;
    border: 2px solid gray;
  }