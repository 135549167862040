.house-image {
  height: 17rem;
}

.house_img {
  width: 100%;
  height: 17rem;
  border-radius: 10px;
}

.delete_house-icon {
  right: 3rem;
  bottom: 2rem;
}

/* PLANS STYLEING  */
.m-body {
  border: 1px solid #919191;
  border-radius: 8px;
  padding: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100px;
  /* width: 220px; */
  cursor: pointer;
}

.m-checkbox {
  display: flex;
  justify-content: flex-end;
  padding: 5px 18px 0 0;
}

.mortgage-text {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px 4px;
}

.m-radio {
  width: 20px;
  height: 20px;
}

.form-check-input:checked {
  background-color: #335f32 !important;
  border: 2px solid #335f32 !important;
  width: 1.3em;
  height: 1.3em;
  outline: none !important;
  box-shadow: inset 0 2px 5px #335f32 !important;
}