.main-wrapper {
  /* padding: 1rem; */
}

.app-item {
  flex: 1;
  width: 100%;
  overflow-x: auto;
  /* position: absolute;
      top: 0;
      right: 0;
      bottom: 0;  */
}

.layoutSidenav {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
}
