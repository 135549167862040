.plans {
  width: 100%;
  height: 138px;
  box-shadow: 0px 6px 6px #00000029;
  border: 0.5px solid #335f32;
  border-radius: 8px;
  cursor: pointer;
}

.plans > span {
  font-size: 16px;
  font-weight: 700;
  font-family: "Red Hat Display", sans-serif;
}

.processing-fees-icon {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 4px 5px 6px 5px;
  box-shadow: 0px 6px 6px #00000029;
  cursor: pointer;
}

.processing-fees-icon > svg {
  width: 22px;
  height: 16px;
}

.btn-buttons {
  position: relative;
  bottom: -36px;
  z-index: 6;
  width: 100%;
  height: 130px;
  background: #f0f0f0;
}

.select_interest_type {
    justify-content: center;
    align-items: center;
    gap: 4rem;
    width: 60rem;
    margin: auto;
}

.select_interest_type > div {
    width: 30rem;
}

@media (max-width: 900px) {
    .select_interest_type {
      width: 100%;
    }

    .select_interest_type > div {
        width: 100%;
    }
  }
