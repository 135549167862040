@media (max-width: 375px) {
  .navbar-custom {
    background-color: #335f32 !important;
  }
}

.navbar-custom {
  background-color: #ffffff;
  display: flex !important;
  align-items: start !important;
}

.nav-title {
  height: 100% !important;
}

.search-form {
  padding: 0 !important;
  margin: 0 !important;
}

.nav-link-bell {
  position: relative;
  border-radius: 50%;
  background-color: #335f32;
  padding: 14px 16px 8px 13px !important;
}

.custom-icon-bell {
  color: white !important;
  font-size: 16px !important;
}

.custom-icon-bell-count {
  position: absolute;
  top: 15px;
  right: 15px;
  transform: translate(50%, -50%);
  min-width: 1rem !important;
  height: 1rem !important;
  line-height: 0.9rem;
  text-align: center;
  font-size: 12px !important;
  font-weight: bold;
  border: 1px solid #335f32 !important;
  color: #335f32 !important;
  background-color: white;
  border-radius: 50%;
  padding: 0 3px;
}

/* .custom-icon-bell {
  height: 55px;
    width: 55px;
    background-color: #335F32; 
}  */

.custom-icon > svg {
  font-size: 25px;
  padding: inherit;
  color: #ffffff;
}

.dropdown-toggle::after {
  margin-left: -0.745em;
  vertical-align: 1.255em;
  color: #ffffff;
}

.custom-form {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}
.form-control {
  background-color: #f0f0f0;
  color: #4b4b4b;
  font-size: 16px;
  border: 1px solid #f0f0f0;
}

.input-group-text {
  color: #335f32;
  text-align: center;
  white-space: nowrap;
  background-color: #f0f0f0;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  border: 1px #f0f0f0 solid;
}

.form-control:focus,
.datatable-input:focus .custom-form:focus {
  color: #4b4b4b;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  outline: 0;
  box-shadow: none;
  font-size: 16px;
}

.navbar-brand {
  background-color: #f0f0f0;
  width: 316px !important;
}
.header-name {
  font-family: "Millik", sans-serif;
  font-size: 30px;
  font-weight: 800;
}
/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .header-name {
    font-size: 32px; /* Slightly smaller font size */
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .header-name {
    font-size: 28px; /* Even smaller font size */
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .header-name {
    font-size: 24px; /* Smallest font size for small screens */
  }
}

.sub-title {
  font-size: 14px;
}

.upload-notification-container {
  background-color: #335f32;
  border: 3px solid #000000;
  border-radius: 5px;
  padding: 10px;
}

.upload-notification {
  color: #ffffff;
  font: 14px;
}

.upload-notification-icon {
  color: #ffffff;
  cursor: pointer;
}

.estate_data span {
  margin-left: 6px;
  font-size: 14px;
}

.form-check-label {
  cursor: pointer;
  padding: 2px 0 0 4px;
  height: 25px;
  font-size: 16px;
  font-weight: 900;
  font-family: "Red Hat Display", sans-serif;
}

.form-check-input:checked {
  background: rgb(43, 79, 43);
  border-color: rgb(43, 79, 43);
}

.form-check-input {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
