.table {
  border: 1px solid #F0F0F0;
  /* margin: 1rem 0; */
}

.sub-header-text{
  font-family: 'Millik', sans-serif;
  font-weight: 700;
}

.table-row-text{
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.table-row-wrapper {
  display: flex;
  justify-content: space-between;
}