/* TabGroup.css */

.tabgroup {
    font-family: "Red Hat Display";
  }
  
  .tabLists {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
  
  .tabList {
    color: #335f32;
    border-radius: 100px;
    border: 2px solid #335f32;
    background-color: white;
    padding: 1px 10px;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    /* text-transform: uppercase; */
    margin-bottom: -1px; /* Align with the bottom border */
    opacity: 1;
    letter-spacing: 0px;
    text-align: left;
    padding: 0.3rem 0.7rem;
  }
  
  .tabList.is-active {
    color: white;
    background-color: #335f32;
  }
  