.headline {
  position: relative;
}

.previousBooking {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 1rem 0.5rem;
  margin-top: 1rem;
}

/* MOBILE INSPECTION CARD DETAILS */
.mobile_inspection_card {
  border-radius: 10px;
  border: 1px solid gray;
  overflow: hidden;
}
.mobile_inspection_card_title {
  color: #335f32;
  font-weight: bold;
  text-align: center;
  background-color: #f0f0f0;
  padding: 0.8rem 1rem;
  margin: 0;
}

.mobile_inspection_card_details {
  justify-content: space-between;
  padding: 0.8rem 1rem;
}

.icon_container {
  display: inline-flex;
  gap: 0.5rem;
}

.icon_container > span > svg {
  height: 1rem
}

.icon_container p {
  font-size: 0.8rem;
  padding-top: 2px;
  margin: 0;
}

.mobile_inspection_card_img {
    height: 10rem;
    border-radius: 7px;
    margin: 0.8rem 0;
    overflow: hidden;
}

.mobile_inspection_card_profil_img {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    overflow: hidden;
}
