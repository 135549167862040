.carrd_wrapper {
  align-self: center;
  justify-self: center;
}

.carrd_wrapper > span {
  margin: auto;
  font-size: 12px;
}
.carrd {
  cursor: pointer;
  border: 1px solid black;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 25px;
}

.position-width_ {
  width: 78.5% !important;
}

.carrd:hover {
  background: rgba(58, 91, 8, 0.071);
}

.image-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 3px dashed #7070703d !important;
  border-radius: 8px;
  height: 315px !important;
  margin: 0 10px;
  cursor: pointer;
  box-shadow: 6px 16px 20px -10px;
}

.image-show > img {
  /* height: 312px; */
  width: 80%;
  border: none;
  border-radius: 8px;
  box-shadow: 6px 16px 20px -10px;
}
.image-show {
  border-radius: 8px;
  /* height: 315px;  */
  cursor: pointer;
  margin: 10px 0;
}

.input-image {
  display: none;
}

.icon-wrapper > span {
  font-family: "Red Hat Display", san-serif;
  font-size: 14px;
  color: #335f32;
  padding-right: 14px;
}
.wrapper {
  margin: 0 25px;
}

.custom-form-lg,
.custom-form-lg:focus {
  font: normal normal bold 14px "Red Hat Display";
  height: 61px;
  border: 1px solid #0000008e;
  border-radius: 100px;
  opacity: 1;
  color: #000000;
  background-color: #fff;
}

.custom-form-lg {
  background-color: #f0f0f0;
}

.custom-form-lg::placeholder {
  font: normal normal normal 12px "Red Hat Display";
  letter-spacing: 0.04px;
  color: #919191;
  opacity: 1;
}

.custom-form-label {
  font-size: 16px !important;
  font-family: "Red Hat Display", sans-serif;
  font-weight: bold;
  letter-spacing: 0.04px;
  padding-left: 10px;
}

.custom-form-lg-textarea,
.custom-form-lg-textarea:focus {
  font: normal normal bold 14px "Red Hat Display";
  border: 1px solid #0000008e;
  border-radius: 8px;
  opacity: 1;
  color: #000000;
  background-color: #fff;
}

.custom-form-label > .required {
  color: red;
}

.btn-background {
  background: #f0f0f0;
  height: 135px;
}

.active > span > svg {
  color: #335f32 !important;
}

.house_image_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  border: 1px solid gray;
  margin-right: 0.7rem;
}

.img_overlay,
.thumb_img_overlay {
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.house_image_wrapper:hover .img_overlay,
.thumb:hover .thumb_img_overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.house_image_wrapper:hover .img_open,
.house_image_wrapper:hover .img_delete_icon,
.thumb .thumb_img_open-icon {
  display: block;
}

.img_open,
.thumb_open {
  position: relative;

  display: none;
  font-weight: bold;
  color: #335f32;
  border: 1px solid #335f32;
  border-radius: 100px;
  background-color: white;
  padding: 4px 10px;
  cursor: pointer;
}

.img_open-icon > span > svg,
.thumb_open_icon > span > svg {
  fill: #335f32 !important;
  stroke: #335f32;
  stroke-width: 4px;
  width: 17px;
  height: 14px;
  margin-right: 0.5rem;
}

.img_type {
  display: inline-block;
  font-size: 0.7rem;
  font-weight: bold;
  color: white;
  border: 1px solid white;
  border-radius: 100px;
  background-color: #335f32;
  padding: 4px 10px;
}

.img_name {
  display: inline-block;
  font-size: 0.7rem;
  font-weight: bold;
  color: #335f32;
  border: 1px solid #335f32;
  border-radius: 100px;
  background-color: white;
  padding: 4px 10px;
}

.img_footer {
  left: 1rem;
  right: 1rem;
  bottom: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img_delete_icon {
  display: none;
}

.thumb {
  width: 5rem;
}

.thumb:hover .thumb_open {
  display: flex;
}

.thumb_img {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 5rem;
  height: 4.5rem;
  border-radius: 10px;
  border: 2px solid gray;
}

.thumb_open {
  font-size: 0.5rem;
  padding: 2px 4px;
  margin-bottom: 1.5rem;
  position: relative;
}

.thumb_open_icon > span > svg {
  width: 11px;
  height: 9px;
  margin-right: 0.2rem;
}

.thumb:hover .delete_icon_thumbs {
  display: block;
}

.delete_icon_thumbs {
  position: absolute;
  top: 2.4rem;
  right: 0.4rem;

  display: none;
}

.plus-icon {
  background-color: #335f32 !important;
  width: 71px;
  height: 71px;
  border-radius: 18px;
}
.plus-icon > span > svg {
  color: #fff !important;
  fill: #fff !important;
  stroke: #fff;
}
.upload-icon {
  background-color: #fff !important;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}
.upload-icon > span > svg {
  color: #335f32 !important;
  fill: #335f32 !important;
  stroke: #335f32;
  width: 25px;
}
.delete-icon {
  display: none; /* Hide the delete icon by default */
  background-color: #f0c0c0 !important;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

/* Styling for the SVG within the delete icon */
.delete-icon > span > svg {
  color: #d10808 !important;
  fill: #d10808 !important;
  stroke: #d10808;
  width: 17px;
}
.position-relative:hover .delete-icon {
  display: inline-block; /* This will show the icon on hover */
}
.delete-icon-thumbs {
  display: none; /* Hide the delete icon by default */
  background-color: #f0c0c0 !important;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #d10808;
  float: right;
}
/* Styling for the SVG within the delete icon */
.delete-icon-thumbs > span > svg {
  color: #d10808 !important;
  fill: #d10808 !important;
  stroke: #d10808;
  width: 8px;
}
.thumbs:hover .delete-icon-thumbs {
  display: flex; /* This will show the icon on hover */
}

.open-icon {
  display: none;
}

/* Styling for the SVG within the delete icon */
.open-icon > span > svg {
  color: #335f32 !important;
  fill: #335f32 !important;
  stroke: #335f32;
  width: 17px;
  height: 14px;
}
.position-relative:hover .open-icon {
  display: inline-block;
}

.image-darken-hover {
  position: relative; /* Needed for positioning the pseudo-element */
  transition: all 0.3s ease; /* Smooth transition for the hover effect */
}

.image-darken-hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Transparent background initially */
  transition: background-color 0.3s ease; /* Smooth transition for the background */
  border-radius: 8px;
}

.image-darken-hover:hover::before {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent black background on hover */
  border-radius: 8px;
}

.image-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  height: 228px;
  width: 100%;
  margin: 0 10px;
  cursor: pointer;
}

.image-show > img {
  height: 228px;
  width: 100% !important;
  border: none;
  border-radius: 8px;
  box-shadow: 6px 16px 20px -10px;
}
.image-show {
  border-radius: 8px;
  height: 248px;
  width: 100%;
  cursor: pointer;
  margin: 0 10px;
}

.input-image {
  display: none;
}

.img-title > img {
  height: 52px !important;
  width: 63px !important;
  border-radius: 8px;
}

/* .img-title > span > svg{ 
  height: 52px !important;
  width: 63px !important;
  border-radius: 8px;
  border: 0.25px solid #7070703f;
  background: #F0F0F0;
  padding: 5px;
} */

.img-title {
  margin: 0 5px;
  font-size: 10px;
  color: green;
  height: 100%;
  cursor: pointer;
  width: 85px;
  word-wrap: break-word;
}

.thumbs-wrapper {
  margin: 10px auto;
  display: flex;
}
